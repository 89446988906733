<template>
    <div class="al-content">
        <div >
            <h1>Profile</h1>
            <div class="panel panel-blur profile-page animated zoomIn">
                <div class="panel-body" >
                    <div class="panel-content ">
                        <h3 class="with-line pt-0">General Information</h3>
                        <!--<div class="row">
        <div class="col-md-6">
            <div class="form-group row clearfix">
                <label for="inputFirstName" class="col-sm-3 control-label">Picture</label>
                <div class="col-sm-9">
                    <div class="userpic">
                        <div class="userpic-wrapper">
                            <img src="@/assets/img/theme/no-photo.png">
                        </div>
                        <a href="" class="change-userpic">Change Profile Picture</a>
                        <input type="file" id="uploadFile" class="hide">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6"></div>
    </div>-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row clearfix">
                                    <label for="inputFirstName" class="col-sm-3 control-label">First Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" id="Fname" placeholder="" :value="User.Fname">
                                    </div>
                                </div>
                                <div class="form-group row clearfix">
                                    <label for="inputLastName" class="col-sm-3 control-label">Last Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" id="Lname" placeholder="" :value="User.Lname">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row clearfix">
                                    <label class="col-sm-3 control-label">Department</label>
                                    <div class="col-sm-9">
                                        
                                            <input type="text" class="form-control" id="inputDepartment" placeholder="" :value="User.Department" readonly>
                                        
                                    </div>
                                </div>
                                <div class="form-group row clearfix">
                                    <label for="inputOccupation" class="col-sm-3 control-label">Position</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" id="inputOccupation" placeholder="" :value="User.Position" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary btn-with-icon save-profile" @click.prevent="UpdateName">
                            <i class="ion-android-checkmark-circle"></i>Update Name
                        </button>
                        <h3 class="with-line">Change Password</h3>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row clearfix">
                                    <label for="inputPassword" class="col-sm-3 control-label">Old Password</label>
                                    <div class="col-sm-9">
                                        <input type="password" class="form-control" id="OldPass" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row clearfix">
                                    <label for="inputPassword" class="col-sm-3 control-label">Password</label>
                                    <div class="col-sm-9">
                                        <input type="password" class="form-control" id="NewPass" placeholder="" value="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row clearfix">
                                    <label for="inputConfirmPassword" class="col-sm-3 control-label">Confirm Password</label>
                                    <div class="col-sm-9">
                                        <input type="password" class="form-control" id="ConfirmPass" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary btn-with-icon save-profile" @click="UpdatePass">
                            <i class="ion-android-checkmark-circle"></i>Update Password
                        </button>
                        <h3 class="with-line">Contact Information</h3>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row clearfix">
                                    <label for="inputEmail3" class="col-sm-3 control-label">Email</label>
                                    <div class="col-sm-9">
                                        <input type="email" class="form-control" id="inputEmail3" placeholder="" :value="User.Email">
                                    </div>
                                </div>
                                <div class="form-group row clearfix">
                                    <label for="inputPhone" class="col-sm-3 control-label">Phone</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" id="inputPhone" placeholder="" :value="User.Phone">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row clearfix">
                                    <label class="col-sm-3 control-label">DOB</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" id="inputDOB" placeholder="" :value="User.Dob">
                                    </div>
                                </div>
                                <div class="form-group row clearfix">
                                    <label for="inputRoom" class="col-sm-3 control-label">Address</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" id="inputAddress" placeholder="" :value="User.Address">
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import $ from 'jquery'
export default {
        name: `UserProfile`,
        data: function () {
            return {
                User: {}
            }
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        created() {

        },
        mounted() {
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                console.log("user index newVal", newVal);
                if (newVal) {
                    this.start();
                }
            }
        },
        
        methods: {
            ...mapActions({
                'showModal': 'view/showModal',
                'loading': 'view/setLoading',
                'call': 'api/call'
            }),
            start: async function() {
                this.loading(true);
                const rs = await this.call({ Action: 'User/GetProfile' })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }
                } else {
                    this.User = rs.Data;                    
                }
            },
            UpdateName: async function() {
                console.log(this);
                this.loading(true);
                const rs = await this.call({ Action: 'User/UpdateName', Object: { FName: $('#Fname').val(), LName: $('#Lname').val()} })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    this.$store.dispatch('view/showToast', { title: '', content: 'Update Name success!', type: 'success' });
                }
            },
            UpdatePass: async function() {
                //check confirm pass
                if ($('#OldPass').val() == "") {
                    this.showModal({ isShow: true, title: 'Error', content: "Old Password is require", type: 'error' });
                    $('#OldPass').focus();
                    return;
                }
                if ($('#NewPass').val()=="") {
                    this.showModal({ isShow: true, title: 'Error', content: "Password is require", type: 'error' });
                    $('#NewPass').focus();
                    return;
                }
                if ($('#NewPass').val() != $('#ConfirmPass').val()) {
                    this.showModal({ isShow: true, title: 'Error', content: "Confirm Password is not correct", type: 'error' });
                    return;
                }
                this.loading(true);
                const rs = await this.call({ Action: 'User/UpdatePass', Object: { OldPass: $('#OldPass').val(),NewPass :$('#NewPass').val() } })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    this.$store.dispatch('view/showToast', { title: '', content: 'Update Name success!', type: 'success' });
                }
            }
        }
        
    
};
</script>
